import {
  CloseCircleFilled,
  PlusCircleFilled,
  SaveFilled,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Descriptions,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Statistic,
  Table,
  Typography,
  message,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";
import { useAbility } from "../contexts/abilityContext";

const { Text } = Typography;
dayjs.extend(customParseFormat);

const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;

const AssignmentTrackingDetails = () => {
  let { t } = useTranslation();
  const { ability } = useAbility();

  const { Company } = useContext(CompanyContext);
  const { id } = useParams();
  const stateParamVal = useLocation().state.stateParam;

  console.log("props parametre value", id);
  console.log("props state value", stateParamVal);

  const [assignmentDetails, setassignmentDetails] = useState();
  const [Customer, setCustomer] = useState();
  const [MarketingType, setMarketingType] = useState();
  const [employee, setemployee] = useState();
  const [SummaryDetails, setSummaryDetails] = useState(null);
  const [datebegin, setDatebegin] = useState(null);
  const [editingRow, setEditingRow] = useState(null);
  const [editingRowbook, setEditingRowbook] = useState(null);
  const [costDataSource, setRevenueDataSource] = useState();
  const [RowRevenueData, setRowRevenueData] = useState();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);

  const [selectedNamedes, setSelectedNamedes] = useState(null);
  const [isModalVisibledes, setIsModalVisibledes] = useState(false);
  const [currentPage, setCurrentPage] = useState(() => {
    return Number(localStorage.getItem("currentPageAssignmentDetail")) || 1;
  });
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    localStorage.setItem("currentPageAssignmentDetail", pagination.current);
  };
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };

  // for dicription
  const showNameModaldes = (name) => {
    setSelectedNamedes(name);
    setIsModalVisibledes(true);
  };
  const closeModaldes = () => {
    setIsModalVisibledes(false);
    setSelectedNamedes(null);
  };

  const disabledDate = (current) => {
    return current && current < datebegin;
  };

  const StatefulModalContent = (props) => {
    return (
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item
          name="customerId"
          label={t("Customer")}
          rules={[
            {
              required: true,
              message: `${t("Pleaseselectacustomer")}`,
            },
          ]}
        >
          <Select
            notFoundContent={t("nodata")}
            placeholder={t("selectcustomer")}
            style={{}}
          >
            {Customer?.map((customer) => (
              <Option key={customer.id} value={customer.id}>
                {customer.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="employeeId"
          label={t("Employee")}
          rules={[
            {
              required: true,
              message: `${t("Pleaseselectanemployee")}`,
            },
          ]}
        >
          <Select
            notFoundContent={t("nodata")}
            placeholder={t("selectemployee")}
            style={{}}
          >
            {employee?.map((employee) => (
              <Option key={employee.id} value={employee.id}>
                {employee.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            style={{ width: "30%" }}
            label={t("billedhours")}
            name="billedHours"
          >
            <InputNumber defaultValue={0} min={0} />
          </Form.Item>

          <Form.Item
            style={{ width: "30%" }}
            label={t("holidayhours")}
            name="holidayHours"
          >
            <InputNumber defaultValue={0} min={0} />
          </Form.Item>

          <Form.Item
            style={{ width: "30%" }}
            label={t("vacationhours")}
            name="vacationHours"
          >
            <InputNumber defaultValue={0} min={0} />
          </Form.Item>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            style={{ width: "30%" }}
            label={t("illnesshours")}
            name="illnessHours"
          >
            <InputNumber defaultValue={0} min={0} />
          </Form.Item>

          <Form.Item
            style={{ width: "30%" }}
            label={t("unpaidhours")}
            name="unpaidHours"
          >
            <InputNumber defaultValue={0} min={0} />
          </Form.Item>

          <Form.Item
            style={{ width: "30%" }}
            label={t("costrate")}
            name="costRate"
          >
            <InputNumber defaultValue={0} min={0} />
          </Form.Item>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            style={{ width: "30%" }}
            label={t("sellingrate")}
            name="sellingRate"
          >
            <InputNumber defaultValue={0} min={0} />
          </Form.Item>
        </div>

        <Form.Item
          name="descriptionCreate"
          label={t("description")}
          rules={[
            {
              required: true,
              message: `${t("EnterDescription")}`,
            },
          ]}
        >
          <TextArea
            placeholder={t("EnterDescription")}
            style={{ fontSize: "16px", height: "100px", padding: "10px" }}
          />
        </Form.Item>
      </Form>
    );
  };

  const CollectionCreateForm = ({ open, onCreate, onCancel, data }) => {
    {
      return data.data === "assignmentDetail" ? (
        <Modal
          open={open}
          title={t("Create")}
          okText={t("Create")}
          cancelText={t("Cancel")}
          onCancel={onCancel}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                onCreate({ values: values, url: data.url, data: data.data });
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          <StatefulModalContent />
        </Modal>
      ) : data.data === "Customer" ? (
        <Modal
          open={open}
          title={t("CreateanewCustomer")}
          okText={t("Create")}
          cancelText={t("Cancel")}
          onCancel={onCancel}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                onCreate({ values: values, url: data.url, data: data.data });
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          <Form form={form} layout="vertical" name="form_in_modal">
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: `${t("writeacostumer")}`,
                },
              ]}
            >
              <Input placeholder={t("writeacostumerplaceholder")} />
            </Form.Item>
          </Form>
        </Modal>
      ) : (
        <Modal
          open={open}
          title={t("CreateanewProduct")}
          okText={t("Create")}
          cancelText={t("Cancel")}
          onCancel={onCancel}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                onCreate({ values: values, url: data.url, data: data.data });
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          <Form form={form} layout="vertical" name="form_in_modal">
            <Form.Item
              name="label"
              rules={[
                {
                  required: true,
                  message: `${t("writeaproduct")}`,
                },
              ]}
            >
              <Input placeholder={t("writeaproductplaceholder")} />
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await axios
      .get(`${JSON_API}/Customers/enterprise/${Company.id}`)
      .then((response) => {
        setCustomer(response.data);
        console.log("all customers ", Customer);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });

    await axios
      .get(`${JSON_API}/Marketing/MarketingType`)
      .then((response) => {
        setMarketingType(response.data);
        console.log("all marketing types ", Customer);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });

    await axios
      .get(`${JSON_API}/Employees/enterprise/filter?enterpriseId=${Company.id}`)
      .then((response) => {
        setemployee(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });

    await axios
      .get(`${JSON_API}/AssignmentTrackingEntrys/${stateParamVal}`)
      .then((response) => {
        console.log("daaaaaaaaatat", response.data);
        setassignmentDetails(response.data);
        setSummaryDetails(response.data.summary);
        console.log(response.data);
      })
      .catch(function (error) {
        setassignmentDetails(null);
        console.log(error.config);
      });
  };

  const handleDelete = async (id) => {
    const filteredArray = assignmentDetails?.assignmentTrackingEntries
      .filter((entry) => entry.id === id)
      .map((entry) => ({
        id: entry.id,
        employeeId: entry.employeeId,
        customerId: entry.customerId,
        billedHours: entry.billedHours,
        holidayHours: entry.holidayHours,
        vacationHours: entry.vacationHours,
        illnessHours: entry.illnessHours,
        unpaidHours: entry.unpaidHours,
        costRate: entry.costRate,
        sellingRate: entry.sellingRate,
        state: 3,
        description: entry.description,
      }));
    console.log("Object Put 1 :", filteredArray);
    await axios
      .put(
        `${JSON_API}/AssignmentTrackingEntrys/${stateParamVal}`,
        filteredArray
      )
      .then(() => {
        getData();
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  function getMonthName(index) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Check if the index is valid
    if (index < 0 || index > months.length) {
      return "Invalid Month";
    }

    return months[index - 1];
  }
  const columns = [
    {
      title: <h3 style={{ textAlign: "center" }}>{t("Employee")}</h3>,
      dataIndex: "employeeId",
      align: "center",
      render: (_, record) => {
        if (editingRowbook === record.id) {
          return (
            <Form.Item
              name="employee"
              rules={[
                {
                  required: true,
                  message: `${t("PleaseSelectEmployee")}`,
                },
              ]}
            >
              <Select
                notFoundContent={t("nodata")}
                placeholder={t("PleaseSelectEmployee")}
                style={{
                  width: 120,
                }}
              >
                {employee?.map(
                  (e) =>
                    e && (
                      <Option value={e.id}>
                        {e.firstName} {e.name}
                      </Option>
                    )
                )}
              </Select>
            </Form.Item>
          );
        } else {
          return (
            <div
              style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              onClick={() =>
                record.employeeId === null
                  ? showNameModal("__")
                  : showNameModal(
                      employee?.find((entry) => entry.id === record.employeeId)
                        ?.firstName +
                        " " +
                        employee?.find(
                          (entry) => entry.id === record.employeeId
                        )?.name || ""
                    )
              }
            >
              {record.employeeId === null
                ? "__"
                : employee?.find((entry) => entry.id === record.employeeId)
                    ?.firstName +
                    " " +
                    employee?.find((entry) => entry.id === record.employeeId)
                      ?.name || ""}
            </div>
          );
        }
      },
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("Customer")}</h3>,
      dataIndex: "name",
      align: "center",
      render: (text, record) => {
        if (editingRowbook === record.id) {
          return (
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: `${t("Pleaseentercustomername")}`,
                },
              ]}
            >
              <Select
                notFoundContent={t("nodata")}
                placeholder={t("Pleaseselectacustomer")}
                style={{
                  width: 120,
                }}
              >
                {Customer?.map(
                  (e) => e && <Option value={e.id}>{e.name}</Option>
                )}
              </Select>
            </Form.Item>
          );
        } else {
          return (
            <>
              {record.customerId === null
                ? "__"
                : Customer?.find((entry) => entry.id === record.customerId)
                    ?.name || ""}
            </>
          );
        }
      },
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("billedhours")}</h3>,
      dataIndex: "billedHours",
      align: "center",
      render: (text, record) => {
        if (editingRowbook === record.id) {
          return (
            <Form.Item name="billedHours">
              <InputNumber min={0} formatter={(value) => `${value}`} />
            </Form.Item>
          );
        } else {
          return (
            <div style={{ textAlign: "center" }}>
              {text ? text.toFixed(2) : 0} h
            </div>
          );
        }
      },
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("holidayhours")}</h3>,
      dataIndex: "holidayHours",
      align: "center",
      render: (text, record) =>
        editingRowbook === record.id ? (
          <Form.Item name="holidayHours">
            <InputNumber min={0} />
          </Form.Item>
        ) : (
          <div style={{ textAlign: "center" }}>
            {text ? text.toFixed(2) : 0} h
          </div>
        ),
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("vacationhours")}</h3>,
      dataIndex: "vacationHours",
      align: "center",
      render: (text, record) =>
        editingRowbook === record.id ? (
          <Form.Item name="vacationHours">
            <InputNumber min={0} />
          </Form.Item>
        ) : (
          <div style={{ textAlign: "center" }}>
            {text ? text.toFixed(2) : 0} h
          </div>
        ),
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("illnesshours")}</h3>,
      dataIndex: "illnessHours",
      align: "center",
      render: (text, record) =>
        editingRowbook === record.id ? (
          <Form.Item name="illnessHours">
            <InputNumber min={0} />
          </Form.Item>
        ) : (
          <div style={{ textAlign: "center" }}>
            {text ? text.toFixed(2) : 0} h
          </div>
        ),
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("unpaidhours")}</h3>,
      dataIndex: "unpaidHours",
      align: "center",
      render: (text, record) =>
        editingRowbook === record.id ? (
          <Form.Item name="unpaidHours">
            <InputNumber min={0} />
          </Form.Item>
        ) : (
          <div style={{ textAlign: "center" }}>
            {text ? text.toFixed(2) : 0} h
          </div>
        ),
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("availablehours")}</h3>,
      dataIndex: "availableHours",
      align: "center",
      render: (text, record) => (
        <div style={{ textAlign: "center" }}>
          {text ? text.toFixed(2) : 0} h
        </div>
      ),
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("paidhours")}</h3>,
      dataIndex: "totalPaidHours",
      align: "center",
      render: (text, record) =>
        editingRowbook === record.id ? (
          <Form.Item name="totalPaidHours">
            <InputNumber min={0} />
          </Form.Item>
        ) : (
          <div style={{ textAlign: "center" }}>
            {text ? text.toFixed(2) : 0} h
          </div>
        ),
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("billingavg")}</h3>,
      dataIndex: "percentBilledHours",
      align: "center",
      render: (text, record) => (
        <div style={{ textAlign: "center" }}>{text ? text.toFixed(2) : 0}%</div>
      ),
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("costrate")}</h3>,
      dataIndex: "costRate",
      align: "center",
      render: (text, record) =>
        editingRowbook === record.id ? (
          <Form.Item name="costRate">
            <InputNumber min={0} />
          </Form.Item>
        ) : (
          <div style={{ textAlign: "center" }}>
            {text ? text.toFixed(2) : 0}
            {Company.currencies &&
            Company.currencies.length > 0 &&
            Company.currencies[0].shortLabel
              ? Company.currencies[0].shortLabel
              : "$"}
          </div>
        ),
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("sellingrate")}</h3>,
      dataIndex: "sellingRate",
      align: "center",

      render: (text, record) =>
        editingRowbook === record.id ? (
          <Form.Item name="sellingRate">
            <InputNumber min={0} />
          </Form.Item>
        ) : (
          <div style={{ textAlign: "center" }}>
            {text ? text.toFixed(2) : 0}
            {Company.currencies &&
            Company.currencies.length > 0 &&
            Company.currencies[0].shortLabel
              ? Company.currencies[0].shortLabel
              : "$"}
          </div>
        ),
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("grossmarginavg")}</h3>,
      dataIndex: "grossMarginPercent",
      align: "center",
      render: (text, record) => (
        <div style={{ textAlign: "center" }}>{text ? text.toFixed(2) : 0}%</div>
      ),
    },
    {
      title: (
        <h3 style={{ textAlign: "center" }}>{t("estimatedclientinvoice")}</h3>
      ),
      dataIndex: "estimatedClientInvoice",
      align: "center",
      render: (text, record) => (
        <div style={{ textAlign: "center" }}>
          {text ? text.toFixed(2) : 0}
          {Company.currencies &&
          Company.currencies.length > 0 &&
          Company.currencies[0].shortLabel
            ? Company.currencies[0].shortLabel
            : "$"}
        </div>
      ),
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("totalclientinvoice")}</h3>,
      dataIndex: "totalClientInvoice",
      align: "center",
      render: (text, record) => (
        <div style={{ textAlign: "center" }}>
          {text ? text.toFixed(2) : 0}
          {Company.currencies &&
          Company.currencies.length > 0 &&
          Company.currencies[0].shortLabel
            ? Company.currencies[0].shortLabel
            : "$"}
        </div>
      ),
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("Variance")}</h3>,
      dataIndex: "variance",
      align: "center",
      width: 250,
      render: (text, record) => {
        const color = text < 0 ? "red" : "black";
        return (
          <>
            <div style={{ textAlign: "center", color: color, width: 150 }}>
              {text ? text.toFixed(2) : 0}
              {Company.currencies &&
              Company.currencies.length > 0 &&
              Company.currencies[0].shortLabel
                ? Company.currencies[0].shortLabel
                : "$"}
            </div>
          </>
        );
      },
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("Description")}</h3>,
      dataIndex: "description",
      align: "center",
      width: 700,
      render: (text, record) => {
        if (editingRowbook === record.id) {
          return (
            <Form.Item name={["description", record.id]} initialValue={text}>
              <TextArea />
            </Form.Item>
          );
        } else {
          return (
            <div
              style={{
                textAlign: "left",
                width: 350,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              onClick={() => showNameModaldes(text)}
            >
              {record.description}
            </div>
          );
        }
      },
    },
  ];

  if (!ability.can("read", "Assignment Tracking")) {
    columns.push({
      title: <h3 style={{ textAlign: "center" }}> {t("Actions")}</h3>,
      dataIndex: "actions",
      align: "center",
      fixed: "center",
      render: (_, record) =>
        editingRowbook === record.id ? (
          <>
            <Button
              type="link"
              icon={<CloseCircleFilled />}
              danger
              onClick={() => {
                setDatebegin(null);
                setEditingRowbook(null);
              }}
            >
              {t("Cancel")}
            </Button>
            <Button type="link" icon={<SaveFilled />} htmlType="submit">
              {t("save")}
            </Button>
          </>
        ) : (
          <>
            {ability.can("update", "Assignment Tracking") && (
              <Popconfirm
                type="link"
                onClick={() => {
                  setEditingRowbook(record.id);
                  form2.setFieldsValue({
                    name: record.customerId,
                    employee: record.employeeId,
                    billedHours: record.billedHours,
                    holidayHours: record.holidayHours,
                    vacationHours: record.vacationHours,
                    illnessHours: record.illnessHours,
                    unpaidHours: record.unpaidHours,
                    availableHours: record.availableHours,
                    totalPaidHours: record.totalPaidHours,
                    percentBilledHours: record.percentBilledHours,
                    costRate: record.costRate,
                    sellingRate: record.sellingRate,
                    grossMarginPercent: record.grossMarginPercent,
                    estimatedClientInvoice: record.estimatedClientInvoice,
                    totalClientInvoice: record.totalClientInvoice,
                    variance: record.variance,
                    description: record.description,
                  });
                }}
              >
                <Button type="link" icon={<EditFilled />}>
                  {t("Edit")}
                </Button>
              </Popconfirm>
            )}
            {ability.can("delete", "Assignment Tracking") && (
              <Popconfirm
                title={t("Suretodelete")}
                cancelText={t("no")}
                okText={t("yes")}
                onConfirm={() => handleDelete(record.id)}
              >
                <Button
                  type="link"
                  danger
                  icon={<DeleteFilled />}
                  style={{ marginLeft: ".5rem" }}
                >
                  {t("Delete")}
                </Button>
              </Popconfirm>
            )}
          </>
        ),
    });
  }

  if (
    ability.can("update", "Assignment Tracking") ||
    ability.can("delete", "Assignment Tracking")
  ) {
    columns.push({
      title: <h3 style={{ textAlign: "center" }}> {t("Actions")}</h3>,
      dataIndex: "actions",
      align: "center",
      fixed: "center",
      render: (_, record) =>
        editingRowbook === record.id ? (
          <>
            <Button
              type="link"
              icon={<CloseCircleFilled />}
              danger
              onClick={() => {
                setDatebegin(null);
                setEditingRowbook(null);
              }}
            >
              {t("Cancel")}
            </Button>
            <Button type="link" icon={<SaveFilled />} htmlType="submit">
              {t("save")}
            </Button>
          </>
        ) : (
          <>
            {ability.can("update", "Assignment Tracking") && (
              <Popconfirm
                type="link"
                onClick={() => {
                  setEditingRowbook(record.id);
                  setDatebegin(dayjs(record.startDate));
                  form2.setFieldsValue({
                    name: record.customerId,
                    employee: record.employeeId,
                    billedHours: record.billedHours,
                    holidayHours: record.holidayHours,
                    vacationHours: record.vacationHours,
                    illnessHours: record.illnessHours,
                    unpaidHours: record.unpaidHours,
                    availableHours: record.availableHours,
                    totalPaidHours: record.totalPaidHours,
                    percentBilledHours: record.percentBilledHours,
                    costRate: record.costRate,
                    sellingRate: record.sellingRate,
                    grossMarginPercent: record.grossMarginPercent,
                    estimatedClientInvoice: record.estimatedClientInvoice,
                    totalClientInvoice: record.totalClientInvoice,
                    variance: record.variance,
                    description: {
                      [record.id]: record.description,
                    },
                  });
                }}
              >
                <Button type="link" icon={<EditFilled />}>
                  {t("Edit")}
                </Button>
              </Popconfirm>
            )}
            {ability.can("delete", "Assignment Tracking") && (
              <Popconfirm
                title={t("Suretodelete")}
                cancelText={t("no")}
                okText={t("yes")}
                onConfirm={() => handleDelete(record.id)}
              >
                <Button
                  type="link"
                  danger
                  icon={<DeleteFilled />}
                  style={{ marginLeft: ".5rem" }}
                >
                  {t("Delete")}
                </Button>
              </Popconfirm>
            )}
          </>
        ),
    });
  }

  const [Open, setOpen] = useState({
    open: false,
    url: null,
    data: null,
  });

  const onCreate = async ({ values, url, data }) => {
    if (data == "assignmentDetail") {
      console.log(values);
      const obj = [
        {
          id: stateParamVal,
          employeeId: values.employeeId,
          customerId: values.customerId,
          billedHours: values.billedHours ? values.billedHours : 0,
          holidayHours: values.holidayHours ? values.holidayHours : 0,
          vacationHours: values.vacationHours ? values.vacationHours : 0,
          illnessHours: values.illnessHours ? values.illnessHours : 0,
          unpaidHours: values.unpaidHours ? values.unpaidHours : 0,
          costRate: values.costRate ? values.costRate : 0,
          sellingRate: values.sellingRate ? values.sellingRate : 0,
          state: 1,
          description: values.descriptionCreate,
        },
      ];
      console.log("Obj 2", obj);
      await axios
        .put(`${JSON_API}/AssignmentTrackingEntrys/${stateParamVal}`, obj)
        .then(() => {
          getData();
          console.log("obj", obj);
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } else {
      values.enterpriseId = Company.id;
      await axios
        .post(`${JSON_API}/${url}`, values)
        .then(() => {
          getData();
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    }
    setDatebegin(null);
    setOpen(false);
  };
  const Edited = async (values) => {
    console.log("values are :", values);
    setDatebegin(null);

    const obj = [
      {
        id: editingRowbook,
        employeeId: values.employee || 0,
        customerId: values.name || 0,
        billedHours: values.billedHours || 0,
        holidayHours: values.holidayHours || 0,
        vacationHours: values.vacationHours || 0,
        illnessHours: values.illnessHours || 0,
        unpaidHours: values.unpaidHours || 0,
        costRate: values.costRate || 0,
        totalPaidHours: values.totalPaidHours || 0,
        sellingRate: values.sellingRate || 0,
        description: values.description?.[editingRowbook],
        state: 2,
      },
    ];
    console.log("Object Put 3 :", obj);

    await axios
      .put(`${JSON_API}/AssignmentTrackingEntrys/${stateParamVal}`, obj)
      .then((response) => {
        console.log("Adham", response);
        getData();
        message.success(t("assignmentupdate"));
        setEditingRowbook(null);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
        setEditingRowbook(null);
      });
  };

  const onFinishEdit = async (values) => {
    console.log("RowRevenueData:", RowRevenueData);

    const orderbookobj = {
      id: RowRevenueData.revenueDetail.id,
      januaryRevenue: values.januaryRevenue,
      februaryRevenue: values.februaryRevenue,
      marchRevenue: values.marchRevenue,
      aprilRevenue: values.aprilRevenue,
      mayRevenue: values.mayRevenue,
      juneRevenue: values.juneRevenue,
      julyRevenue: values.julyRevenue,
      augustRevenue: values.augustRevenue,
      septemberRevenue: values.septemberRevenue,
      octoberRevenue: values.octoberRevenue,
      novemberRevenue: values.novemberRevenue,
      decemberRevenue: values.decemberRevenue,
    };

    console.log("orderbookcostedited: ", orderbookobj);

    await axios
      .put(`${JSON_API}/Orders/UpdateRevenueDetail`, orderbookobj)
      .then((response) => {
        console.log("Orderbook updated Successfully!", response);
        getData();
        setRevenueDataSource([response.data.revenueDetail]);
        message.success(t("OrdercostdetailsupdatedSuccessfully"));
      });
    setEditingRow(null);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows[0]
      );

      const obj = selectedRows[0].revenueDetail;

      setRowRevenueData(selectedRows[0]);

      obj.key = selectedRowKeys[0];

      setRevenueDataSource([obj]);
    },
  };

  const DisplaySummary = () => {
    const items = [];

    const months = [
      t("billedhours"),
      t("vacationhours"),
      t("unpaidhours"),
      t("paidhours"),
      t("grossmarginavg"),
      t("totalclientinvoice"),
      t("holidayhours"),
      t("illnesshours"),
      t("availablehours"),
      t("billingavg"),
      t("estimatedclientinvoice"),
      t("variance"),
    ];
    console.log(SummaryDetails);
    const revenueData = [
      SummaryDetails && SummaryDetails.totalBilledHours,
      SummaryDetails && SummaryDetails.totalVacationHours,
      SummaryDetails && SummaryDetails.totalUnpaidHours,
      SummaryDetails && SummaryDetails.totalPaidHours,
      SummaryDetails && SummaryDetails.averageGrossMarginPercent,
      SummaryDetails && SummaryDetails.totalClientInvoice,
      SummaryDetails && SummaryDetails.totalHolidayHours,
      SummaryDetails && SummaryDetails.totalIllnessHours,
      SummaryDetails && SummaryDetails.totalAvailableHours,
      SummaryDetails && SummaryDetails.averageBillingHoursPercent,
      SummaryDetails && SummaryDetails.totalEstimatedClientInvoice,
      SummaryDetails && SummaryDetails.totalVariance,
    ];

    months.forEach((month, index) => {
      const monthIndex = index % months.length;
      const monthName = months[monthIndex];
      const revenue = revenueData[monthIndex];

      const color =
        monthName === t("variance") && revenue < 0 ? "red" : "black";
      items.push(
        <Descriptions.Item
          key={monthName}
          style={{ textAlign: "center", minWidth: "120px" }}
          label={monthName}
        >
          <p style={{ textAlign: "center", color: color }}>
            {revenue ? revenue.toFixed(2) : 0}
            {monthName === t("billingavg") || monthName === t("grossmarginavg")
              ? " %"
              : monthName === t("totalclientinvoice") ||
                monthName === t("estimatedclientinvoice") ||
                monthName === t("variance")
              ? " " +
                (Company.currencies &&
                Company.currencies.length > 0 &&
                Company.currencies[0].shortLabel
                  ? Company.currencies[0].shortLabel
                  : " $")
              : " h"}
          </p>
        </Descriptions.Item>
      );
    });

    return (
      <div style={{ overflowX: "auto" }}>
        <Descriptions
          bordered
          column={6}
          style={{ width: "fit-content" }}
          size={"small"}
        >
          {items}
        </Descriptions>
      </div>
    );
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Typography.Text
          title={t("AssignmentTracking")}
          style={{ fontSize: "20px" }}
        >
          {t("AssignmentTracking")}:{" "}
        </Typography.Text>
        <Typography.Text
          title={t("AssignmentTracking")}
          style={{ color: "#3f8600", fontSize: "20px" }}
        >
          {assignmentDetails?.name}
        </Typography.Text>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "21px",
          }}
        >
          <div style={{ marginRight: "20px" }}>
            <Typography.Text title={t("Year")} style={{ fontSize: "20px" }}>
              {t("Year")}:{" "}
            </Typography.Text>
            <Typography.Text
              title={t("Year")}
              style={{ color: "#3f8600", fontSize: "20px" }}
            >
              {assignmentDetails?.year}
            </Typography.Text>
          </div>
          <div style={{ marginRight: "20px" }}>
            <Typography.Text title={t("Month")} style={{ fontSize: "20px" }}>
              {t("Month")}:{" "}
            </Typography.Text>
            <Typography.Text
              title={t("Month")}
              style={{ color: "#3f8600", fontSize: "20px" }}
            >
              {t(`${getMonthName(assignmentDetails?.month)}`)}
            </Typography.Text>
          </div>
        </div>
      </div>
      <Title level={4} style={{ marginTop: "20px" }}>
        {t("Summary")}
      </Title>
      <DisplaySummary />

      <Title level={4}>{t("AssignmentTracking")}</Title>
      <Row justify="end" gutter={[16, 16]}>
        <Space
          style={{
            display: "flex",
            marginBottom: 16,
          }}
          align="center"
        >
          {ability.can("update", "Assignment Tracking") && (
            <>
              <Button
                onClick={() => {
                  setOpen({ open: true, url: "Customers", data: "Customer" });
                }}
              >
                <PlusCircleFilled /> {t("CreateCustomer")}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setOpen({
                    open: true,
                    url: "assignmentdetail",
                    data: "assignmentDetail",
                  });
                }}
              >
                <PlusCircleFilled /> {t("Create")}
              </Button>
            </>
          )}
        </Space>
      </Row>

      <CollectionCreateForm
        open={Open.open}
        onCreate={onCreate}
        onCancel={() => {
          form.resetFields();
          setDatebegin(null);
          setOpen({ open: false, url: null, data: null });
        }}
        data={Open}
      />

      {assignmentDetails && assignmentDetails.assignmentTrackingEntries && (
        <Form form={form2} onFinish={Edited}>
          <div style={{ overflowX: "auto", backgroundColor: "white" }}>
            <Table
              locale={{
                emptyText: <Empty description={t("nodata")} />,
              }}
              pagination={{ current: currentPage }}
              onChange={handleTableChange}
              bordered
              columns={columns}
              dataSource={assignmentDetails.assignmentTrackingEntries}
              summary={(pageData) => {
                const items = [];

                const months = [
                  t("billedhours"),
                  t("holidayhours"),
                  t("vacationhours"),
                  t("illnesshours"),
                  t("unpaidhours"),
                  t("availablehours"),
                  t("paidhours"),
                  t("billingavg"),
                  t("costrate"),
                  t("sellingrate"),
                  t("grossmarginavg"),
                  t("estimatedclientinvoice"),
                  t("totalclientinvoice"),
                  t("variance"),
                ];
                console.log(SummaryDetails);
                const revenueData = [
                  SummaryDetails && SummaryDetails.totalBilledHours,
                  SummaryDetails && SummaryDetails.totalHolidayHours,
                  SummaryDetails && SummaryDetails.totalVacationHours,
                  SummaryDetails && SummaryDetails.totalIllnessHours,
                  SummaryDetails && SummaryDetails.totalUnpaidHours,
                  SummaryDetails && SummaryDetails.totalAvailableHours,
                  SummaryDetails && SummaryDetails.totalPaidHours,
                  SummaryDetails && SummaryDetails.averageBillingHoursPercent,
                  "",
                  "",
                  SummaryDetails && SummaryDetails.averageGrossMarginPercent,
                  SummaryDetails && SummaryDetails.totalEstimatedClientInvoice,
                  SummaryDetails && SummaryDetails.totalClientInvoice,

                  SummaryDetails && SummaryDetails.totalVariance,
                ];

                months.forEach((month, index) => {
                  const monthIndex = index % months.length;
                  const monthName = months[monthIndex];
                  const revenue = revenueData[monthIndex];
                  const color =
                    monthName === t("variance") && revenue < 0
                      ? "red"
                      : "black";
                  items.push(
                    <Table.Summary.Cell index={monthIndex}>
                      <p
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          color: color,
                        }}
                      >
                        {monthName === t("costrate") ||
                        monthName === t("sellingrate")
                          ? ""
                          : revenue
                          ? monthName === t("billingavg") ||
                            monthName === t("grossmarginavg")
                            ? revenue.toFixed(2)
                            : revenue.toFixed(2)
                          : 0}
                        {monthName === t("billingavg") ||
                        monthName === t("grossmarginavg")
                          ? " %"
                          : monthName === t("costrate") ||
                            monthName === t("sellingrate")
                          ? ""
                          : monthName === t("totalclientinvoice") ||
                            monthName === t("estimatedclientinvoice") ||
                            monthName === t("variance")
                          ? " " +
                            (Company.currencies &&
                            Company.currencies.length > 0 &&
                            Company.currencies[0].shortLabel
                              ? Company.currencies[0].shortLabel
                              : " $")
                          : " h"}
                      </p>
                    </Table.Summary.Cell>
                  );
                });

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={1} colSpan={2}>
                        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
                          Total
                        </h3>
                      </Table.Summary.Cell>
                      {items}
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </div>

          <Modal
            title={t("description")}
            visible={isModalVisibledes}
            onOk={closeModaldes}
            onCancel={closeModaldes}
          >
            <p>{selectedNamedes}</p>
          </Modal>
        </Form>
      )}
    </>
  );
};

AssignmentTrackingDetails.acl = {
  subject: "Assignment Tracking",
  action: "read",
};

export default AssignmentTrackingDetails;
