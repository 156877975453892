import { Menu } from "antd";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/blueskylogo.png";
import { CompanyContext } from "../../contexts/CompanyContext";

import {
  AimOutlined,
  AppstoreAddOutlined,
  BlockOutlined,
  BookFilled,
  BookOutlined,
  DollarCircleFilled,
  HomeFilled,
  NotificationOutlined,
  ProfileOutlined,
  ReconciliationOutlined,
  SettingOutlined,
  UnlockOutlined,
  UserOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import SubMenu from "antd/es/menu/SubMenu";
import { useAbility } from "../../contexts/abilityContext";
import { JSON_API_logo } from "../../services/Constants";

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  let { t } = useTranslation();
  const { ability } = useAbility();
  const { Company } = useContext(CompanyContext);

  const tables = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const menuStructure = [
    {
      title: t("general"),
      items: [
        {
          title: t("dashboard"),
          icon: <HomeFilled />,
          link: "/dashboard",
          subject: "Dashboard",
          action: "read",
        },
        {
          title: t("generalinf"),
          icon: tables,
          link: "/generalinformations",
          subject: "General Informations",
          action: "read",
        },
      ],
    },
    {
      title: t("strategicplanning"),
      items: [
        {
          title: t("strategicplanning"),
          icon: <AimOutlined />,
          link: "/strategic_planning",
          subject: "Strategic Planning",
          action: "read",
        },
      ],
    },
    {
      title: t("sales"),
      items: [
        {
          title: t("Dashboard"),
          icon: <BookFilled />,
          link: "/SalesDashboard",
          subject: "Order book",
          action: "read",
        },
        {
          title: t("SalesBudgetDetails"),
          icon: <FileSearchOutlined />,
          link: "/sales-budget",
          subject: "Sales Budget",
          action: "read",
        },
        {
          title: t("orderbook"),
          icon: <BookFilled />,
          link: "/orderbooks",
          subject: "Order book",
          action: "read",
        },
        {
          title: t("opportunitybook"),
          icon: <BookOutlined />,
          link: "/opportunities",
          subject: "Opportunity Book",
          action: "read",
        },

        {
          title: t("PerformanceMonitoring"),
          icon: <BookOutlined />,
          link: "/performance-monitoring",
          subject: "Sales Budget",
          action: "read",
        },
      ],
    },
    {
      title: t("Revenues"),
      items: [
        {
          title: t("Dashboard"),
          icon: <ReconciliationOutlined />,
          link: "/RevenueDashboard",
          subject: "Revenue",
          action: "read",
        },
        {
          title: t("RevenueDetail"),
          icon: <ReconciliationOutlined />,
          link: "/revenue",
          subject: "Revenue",
          action: "read",
        },
      ],
    },
    // {
    //   title: t("SalesBudget"),
    //   items: [
    //     {
    //       title: t("SalesBudgetDetails"),
    //       icon: <FileSearchOutlined />,
    //       link: "/sales-budget",
    //       subject: "Sales Budget",
    //       action: "read",
    //     },
    //   ],
    // },
    {
      title: t("Expenses"),
      items: [
        {
          title: t("Dashboard"),
          icon: <ReconciliationOutlined />,
          link: "/ExpenseDashboard",
          subject: "Expense",
          action: "read",
        },
        {
          title: t("ExpenseDetails"),
          icon: <ReconciliationOutlined />,
          link: "/expense",
          subject: "Expense",
          action: "read",
        },
      ],
    },
    {
      title: t("HumanResources"),
      items: [
        {
          title: t("Dashboard"),
          icon: <ReconciliationOutlined />,
          link: "/RHDashboard",
          subject: "HR planning",
          action: "read",
        },
        {
          title: t("HumanResource"),
          icon: <UserOutlined />,
          link: "/human_resources",
          subject: "Employees Management",
          action: "read",
        },
        {
          title: t("HumanResourcesPlanning"),
          icon: <UserOutlined />,
          link: "/human_resources_planning",
          subject: "HR planning",
          action: "read",
        },
      ],
    },
    {
      title: t("Marketing"),
      items: [
        {
          title: t("Dashboard"),
          icon: <NotificationOutlined />,
          link: "/MarketingDashboard",
          subject: "Marketing Expenses",
          action: "read",
        },
        {
          title: t("Marketingexpenses"),
          icon: <NotificationOutlined />,
          link: "/marketing",
          subject: "Marketing Expenses",
          action: "read",
        },
      ],
    },
    {
      title: t("Production"),
      items: [
        {
          title: t("grossMargins"),
          icon: <ReconciliationOutlined />,
          link: "/devis",
          subject: "Gross Margins",
          action: "read",
        },
        {
          title: t("AssignmentTracking"),
          icon: <BookFilled />,
          link: "/AssignmentTracking",
          subject: "Assignment Tracking",
          action: "read",
        },
      ],
    },
    {
      title: t("Financialstatements"),
      items: [
        {
          title: t("Asset"),
          icon: <ReconciliationOutlined />,
          link: "/Assets",
          subject: "Assets",
          action: "read",
        },
        {
          title: t("liabilities"),
          icon: <ReconciliationOutlined />,
          link: "/liabilities",
          subject: "Liabilities",
          action: "read",
        },
        {
          title: t("equity"),
          icon: <ReconciliationOutlined />,
          link: "/equity",
          subject: "Retained Earnings",
          action: "read",
        },
      ],
    },
    {
      title: t("Finance"),
      items: [
        {
          title: t("Loan"),
          icon: <DollarCircleFilled />,
          link: "/loan",
          subject: "Loans",
          action: "read",
        },
        {
          title: t("Margin"),
          icon: <DollarCircleFilled />,
          link: "/margin",
          subject: "Margins",
          action: "read",
        },
      ],
    },
    {
      title: t("CashFlow"),
      items: [
        {
          title: t("CashFlowInstances"),
          icon: <SettingOutlined />,
          link: "/CashFlowInstances",
          subject: "CashFlow",
          action: "read",
        },
      ],
    },
    {
      title: t("Investments"),
      items: [
        {
          title: t("Dashboard"),
          icon: <DollarCircleFilled />,
          link: "/InvestmentDashboard",
          subject: "Investments",
          action: "read",
        },
        {
          title: t("Investments"),
          icon: <DollarCircleFilled />,
          link: "/Investments",
          subject: "Investments",
          action: "read",
        },
      ],
    },
    {
      title: t("Reports"),
      items: [
        {
          title: t("Reportingsection"),
          icon: <DollarCircleFilled />,
          link: "/report_section",
          subject: "Reporting Section",
          action: "read",
        },
      ],
    },
    {
      title: t("Chartaccounts"),
      items: [
        {
          title: t("Chartaccounts"),
          icon: <ReconciliationOutlined />,
          link: "/chartofaccounts",
          subject: "Chart of Accounts",
          action: "read",
        },
        {
          title: t("Hypotheses"),
          icon: <BlockOutlined />,
          link: "/glaccountsandhyphotheses",
          subject: "Hypothesis",
          action: "read",
        },
      ],
    },
    {
      title: t("Security"),
      items: [
        {
          title: t("ManageSections"),
          icon: <ProfileOutlined />,
          link: "/manageSections",
          subject: "Manage Sections",
          action: "read",
        },
        {
          title: t("Roles"),
          icon: <UserSwitchOutlined />,
          link: "/Roles",
          subject: "Roles",
          action: "read",
        },
        {
          title: t("Accounts"),
          icon: <UsergroupAddOutlined />,
          link: "/accounts",
          subject: "Accounts",
          action: "read",
        },
        {
          title: t("Plans"),
          icon: <AppstoreAddOutlined />,
          link: "/plans",
          subject: "Plans",
          action: "read",
        },
        {
          title: t("ManageAccess"),
          icon: <UnlockOutlined />,
          link: "/manageAccess",
          subject: "Manage Access",
          action: "read",
        },
      ],
    },
    {
      title: t("Parameters"),
      items: [
        {
          title: t("Configurations"),
          icon: <SettingOutlined />,
          link: "/SettingsMenu",
          subject: "Configurations",
          action: "read",
        },
      ],
    },
  ];

  function filterItemsByAbility(ability, items) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      console.log(
        item.subject,
        item.subject && item.action
          ? ability.can(item.action, item.subject)
          : true
      );
    }
    return items.filter((item) =>
      item.subject && item.action
        ? ability && ability.can(item.action, item.subject)
        : true
    );
  }
  function filterMenuByAbility(ability, menu) {
    return menu
      .map((section) => ({
        ...section,
        items: filterItemsByAbility(ability, section.items),
      }))
      .filter((section) => section.items.length > 0);
  }
  console.log("Company.logoPath: ", Company ? Company.logoPath : null);
  return (
    <>
      <div className="brand">
        <NavLink to="/">
          <img
            src={
              Company
                ? Company.logoPath
                  ? JSON_API_logo + Company.logoPath
                  : logo
                : logo
            }
            alt="bleusky logo"
            style={{ width: "100%", height: "5rem" }}
          />
        </NavLink>

        {/* <span>Muse Dashboard</span> */}
      </div>
      <hr />
      {ability && (
        <Menu theme="light" mode="inline">
          {filterMenuByAbility(ability, menuStructure).map((menu, i) => (
            <>
              <Menu.Item className="menu-item-header" key={i}>
                {menu.title}
              </Menu.Item>
              {menu.items.map((item, index) => (
                <Menu.Item key={i + "-" + index}>
                  <NavLink to={item.link}>
                    <span
                      className="icon"
                      style={{
                        background: "",
                      }}
                    >
                      {item.icon}
                    </span>
                    <span className="label">{item.title}</span>
                  </NavLink>
                </Menu.Item>
              ))}
            </>
          ))}
        </Menu>
      )}
    </>
  );
}

export default Sidenav;
