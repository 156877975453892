import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  ConfigProvider,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Typography,
  message,
} from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";
import BudgetEx from "./Tables/BudgetEx";
import HyphothesisOfGlEx from "./Tables/HyphothesisOfGlEx";
import PerformanceEx from "./Tables/PerformanceEx";
import RealsEx from "./Tables/RealsEx";

import { Link, useHistory, useParams } from "react-router-dom";
import { useAbility } from "../../contexts/abilityContext";
const { Option } = Select;

const { TextArea } = Input;
const { Text } = Typography;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 12,
  },
};

// ON CHANGE SELECT
const handleChange = (value) => {
  console.log(`selected ${value}`);
};
// For tabs
const onChange = (key) => {
  console.log(key);
};

//for tabs Form
const onChangee = (key) => {
  console.log(key);
};

function ExpenseDetails() {
  const { ability } = useAbility();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { Company } = useContext(CompanyContext);
  const [gifi, setGifi] = useState("");
  const [glAccount, setglAccount] = useState(null);
  const [Hypo, setHypo] = useState(null);
  const [statementcategory, setStatementCategory] = useState([{}]);
  const [payment, setpayment] = useState([{}]);

  const [statementclass, setStatementClass] = useState([{}]);

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const { id } = useParams();
  const [balance, setbalance] = useState("");
  const { TextArea } = Input;
  const [Asset, setAsset] = useState([]);
  const [Real, setReal] = useState([]);
  const [performance, setperformance] = useState([]);
  const [Description, setDescription] = useState("");
  const [glAccountDescription, setGlAccountDescription] = useState("");

  const [PaymentTerm, setPaymentTerm] = useState();
  const [paymentTermlabel, setpaymentTermlabel] = useState("");
  const [paymentTermId, setpaymentTermId] = useState();
  const [Category, setCategory] = useState();
  const [CategoryName, setCategoryName] = useState();
  const [Class, setClass] = useState();
  const [open, setOpen] = useState(false);
  const [glAccountId, setglAccountId] = useState(null);
  const [taxable, settaxable] = useState(true);
  const history = useHistory();
  const canUpdate = ability.can("update", "Expense");

  useEffect(() => {
    getExpense();
    console.log(CategoryName);
    getReals();
    getPerformance();
    getStatementClass();
    getHypothesis();
  }, [Company.id]);

  function handleredirection() {
    history.push("/expense");
    getExpense();
  }

  const getExpense = async () => {
    try {
      const res = await axios.get(`${JSON_API}/Expense/ExpenseBudgets/${id}`);
      console.log("dataaaaa:", res.data);
      setAsset(res.data);

      if (res.data.financialStatementCategory) {
        setCategory(res.data.financialStatementCategory.id);
        setCategoryName(
          currentLang === "fr"
            ? res.data.financialStatementCategory.labelFr
            : res.data.financialStatementCategory.label
        );
      }

      if (res.data.financialStatementClass) {
        setClass(res.data.financialStatementClass.id);

        handleclass(res.data.financialStatementClass.id);
      }

      setGifi(res.data.financialStatementType.gifi);
      setglAccount(res.data.glAccount.glNumber);
      setDescription(res.data.glAccount.description);
      setGlAccountDescription(
        `(${res.data.glAccount.glNumber}) : ${res.data.glAccount.description}`
      );
      if (res.data.glAccount && res.data.glAccount.id) {
        getHypothesis(res.data.glAccount.id);
      } else {
        console.error(
          "glAccount or glAccount ID is missing in the response data"
        );
      }

      setPaymentTerm(res.data.paymentTermId);
      settaxable(res.data.taxable);

      setpaymentTermId(res.data.paymentTermId);
      const paymentTerm = payment.find((e) => e.id === res.data.paymentTermId);
      setpaymentTermlabel(paymentTerm ? paymentTerm.label : "");
    } catch (err) {
      console.error("Error fetching expense details:", err);
    }
  };

  const getPerformance = async () => {
    await axios
      .get(`${JSON_API}/Expense/ExpensePerformance/${id}`)
      .then((res) => {
        console.log("test", res.data);
        setperformance(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getReals = async () => {
    await axios
      .get(`${JSON_API}/Expense/ExpenseReal/${id}`)
      .then((res) => {
        console.log("test", res.data);
        setReal(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteFinancialStatement = async () => {
    await axios
      .delete(`${JSON_API}/Expense/${Asset.id}`)
      .then((res) => {
        console.log(res.data);
        getExpense();
        message.success(t("ExpenseDeletedSuccessfully"));
      })
      .catch((err) => {
        console.log(err);
        message.error(t("ErrorDeletingExpense"));
      });
  };

  const UpdateFinancialStatement = async () => {
    console.log(Asset);
    console.log(Description);

    const obj = {
      id: Asset.id,
      financialStatementClassId: Asset.financialStatementClass.id,
      financialStatementCategoryId: Category,
      paymentTermId: PaymentTerm,
      taxable: taxable,
      description: Description,
      budgets: Asset.budgets,
      reals: Real.reals,
    };

    await axios
      .put(`${JSON_API}/Expense/ExpenseBudgetUpdate`, obj)
      .then((res) => {
        getExpense();
        message.success(t("UpdatedSuccessfully"));
        //history.push("/expense");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  function handleBudgetChange(budget) {
    setAsset((prevState) => {
      return { ...prevState, budgets: budget };
    });

    Real.reals.map((e) => {
      budget.map((Budget) => {
        if (e.year == Budget.year) {
          const confirm = !Budget.confirmed;
          if (confirm) {
            const startingMonthIndex = Company.startPeriod - 1;
            for (let i = 0; i < months.length; i++) {
              const monthIndex = (i + startingMonthIndex) % months.length;
              const monthName = months[monthIndex];
              e.reals[monthIndex] = Budget.budgets[monthIndex];
            }
          }
        }
      });
    });

    console.log(Asset.budgets);
  }
  function handleRealChange(real) {
    setReal((prevState) => {
      return { ...prevState, reals: real };
    });
  }

  useEffect(() => {
    const loadData = async () => {
      await getExpense();
      await getStatementClass();
      await getHypothesis();
      await Payement();
    };

    loadData();
  }, []);

  const getHypothesis = async (e) => {
    if (typeof e === "undefined" || e === null) {
      console.error("Error: The ID is undefined or null in getHypothesis.");
      return;
    }

    await axios
      .get(`${JSON_API}/GLAccount/${e}`)
      .then((res) => {
        console.log("hypothesis: ", res.data);
        console.log("Hypothesis ID", e);
        setHypo(res.data);
      })
      .catch((err) => {
        console.error("Error in getHypothesis: ", err);
      });
  };

  const handleclass = async (ClassId) => {
    await axios
      .get(`${JSON_API}/FinancialStatementCategory/class/${ClassId}`)
      .then((res) => {
        console.log(res);
        setStatementCategory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStatementClass = async () => {
    await axios
      .get(`${JSON_API}/FinancialStatementClass`)
      .then((res) => {
        console.log(res);
        setStatementClass(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Payement = async () => {
    await axios
      .get(`${JSON_API}/PaymentTerm`)
      .then((res) => {
        console.log(res);
        setpayment(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const itemsForm = [
    {
      key: "1",
      align: "center",
      label: (
        <span style={{ width: 300, textAlign: "center" }}>
          {t("Maininformation")}
        </span>
      ),
      children: Asset.budgets && (
        <div>
          <Form
            {...layout}
            name="nest-messages"
            style={{
              maxWidth: 800,
              margin: "auto",
            }}
          >
            <Form.Item label={t("Class")}>
              <Input
                value={
                  currentLang === "fr"
                    ? Asset.financialStatementClass?.labelFr
                    : Asset.financialStatementClass?.label
                }
                disabled
              />
            </Form.Item>

            <Form.Item label={t("Category")}>
              <Select
                disabled={!canUpdate}
                value={Category}
                onChange={(value) => setCategory(value)}
              >
                {statementcategory &&
                  statementcategory.map((e) => (
                    <Option key={e.id} value={e.id}>
                      {currentLang === "fr" ? e.labelFr : e.label}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item label={"Type"}>
              <Input value={gifi} disabled />
            </Form.Item>

            <Form.Item label={t("glnumber")}>
              <Input value={glAccountDescription} disabled={true} />
            </Form.Item>

            <Form.Item label={t("PayementTerme")}>
              <Select
                placeholder={t("PleaseSelectPaymentTerm")}
                disabled={!canUpdate}
                value={paymentTermId}
                onChange={(value) => setpaymentTermId(value)}
              >
                {payment &&
                  payment.map(
                    (e) =>
                      e && (
                        <Option key={e.id} value={e.id}>
                          {e.label}
                        </Option>
                      )
                  )}
              </Select>
            </Form.Item>

            <Form.Item
              style={{
                textAlign: "right",
              }}
            >
              <Checkbox
                disabled={!canUpdate}
                checked={taxable}
                onChange={(e) => settaxable(e.target.checked)}
              >
                {t("Taxable")}
              </Checkbox>
            </Form.Item>

            <Form.Item label={t("Description")}>
              <Input.TextArea
                disabled={!canUpdate}
                onChange={(e) => setDescription(e.target.value)}
                style={{
                  width: 400,
                }}
                value={Description}
                rows={2}
              />
            </Form.Item>
            <Form.Item></Form.Item>
          </Form>
        </div>
      ),
    },
    {
      key: "2",
      align: "center",
      label: (
        <span style={{ width: 300, textAlign: "center" }}>
          {t("Hyphothesis")}
        </span>
      ),
      children: (
        <div>{Hypo && <HyphothesisOfGlEx HypothesesDataSource={Hypo} />}</div>
      ),
    },
  ];

  const items = [
    {
      key: "1",
      align: "center",

      label: <span style={{ width: 200, textAlign: "center" }}>Budget</span>,
      children: (
        <div>
          {Asset.budgets && (
            <BudgetEx
              AssetBudgets={Asset.financialStatementClass && Asset.budgets}
              onBudgetChange={handleBudgetChange}
            />
          )}
        </div>
      ),
    },
    {
      key: "2",
      align: "center",
      label: (
        <span style={{ width: 200, textAlign: "center" }}>{t("Real")}</span>
      ),
      children: (
        <div>
          {Asset.budgets && (
            <RealsEx
              AssetReals={Real.reals}
              AssetBudgets={Asset.financialStatementClass && Asset.budgets}
              onRealChange={handleRealChange}
            />
          )}
        </div>
      ),
    },
    {
      key: "3",
      align: "center",
      label: (
        <span style={{ width: 200, textAlign: "center" }}>Performance</span>
      ),
      children: (
        <div>
          {Asset.budgets && (
            <PerformanceEx
              AssetReals={Real.reals}
              AssetBudgets={Asset.financialStatementClass && Asset.budgets}
              AssetPerformance={performance.performances}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <Card
        bordered={false}
        className="header-solid mb-24"
        title={
          <h3 className="font-semibold">
            {t("ExpenseDetails")} {t("for")} {Description}{" "}
          </h3>
        }
      >
        <Row justify="space-between">
          <ConfigProvider
            theme={{
              token: {},
            }}
          >
            <Space
              style={{
                display: "flex",
                marginLeft: "flex",
              }}
              align="baseline"
            >
              {ability.can("update", "Expense") && (
                <Button
                  type="primary"
                  size="medium"
                  textAlign="center"
                  onClick={UpdateFinancialStatement}
                >
                  <SaveOutlined />
                  {t("save")}
                </Button>
              )}
            </Space>
          </ConfigProvider>

          <Space>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#FFA805",
                },
              }}
            >
              <Link to="/expense">
                {ability.can("read", "Expense") && (
                  <Button
                    type="primary"
                    style={{
                      textAlign: "right",
                      marginLeft: "flex",
                    }}
                  >
                    <InfoCircleOutlined />
                    {t("backtofinancialstatement")}
                  </Button>
                )}
              </Link>
            </ConfigProvider>

            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#FF0606",
                },
              }}
            >
              {ability.can("delete", "Expense") && (
                <Button
                  type="primary"
                  style={{
                    textAlign: "right",
                    marginLeft: "flex",
                  }}
                  onClick={() => setOpen(true)}
                >
                  <DeleteOutlined />
                  {t("deletStatement")}
                </Button>
              )}
              <Modal
                title={t("Deletethestatment")}
                showIcon
                closable
                description={t("Areyousuretodeletethisone")}
                centered
                open={open}
                onOk={() => {
                  setOpen(false);
                  DeleteFinancialStatement(false);
                  handleredirection();
                }}
                okText={t("yes")}
                cancelText={t("Cancel")}
                onCancel={() => setOpen(false)}
                width={400}
              >
                <span>{t("Areyousuretodeletethisone")} </span>
              </Modal>
            </ConfigProvider>
          </Space>
        </Row>
        <br></br>

        {/* <Table columns={columns} dataSource={ChartofAccounts} bordered /> */}
        <Tabs
          style={{ marginBottom: 32, Color: "#059BFF" }}
          type="card"
          centered
          defaultActiveKey="1"
          items={itemsForm}
          onChange={onChangee}
        />
        <div>
          <Tabs
            style={{ marginBottom: 32, Color: "#059BFF" }}
            type="card"
            centered
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
          />
        </div>
      </Card>
    </div>
  );
}
ExpenseDetails.acl = {
  subject: "Expense",
  action: "read",
};

export default ExpenseDetails;
