import {
  DollarCircleFilled,
  PrinterOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { Button, Card, DatePicker, Empty, Space, Table, Tabs } from "antd";
import axios from "axios";
import { saveAs } from "file-saver";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { CompanyContext } from "../contexts/CompanyContext";
import { Months } from "../contexts/investment";
import { JSON_API } from "../services/Constants";

const { TabPane } = Tabs;

const InvestmentSummary = () => {
  const { Company } = useContext(CompanyContext);
  const [Categories, setCategories] = useState([]);
  const [year, setYear] = useState(null);
  const [Investments, setInvestments] = useState(null);
  const [budgets, setBudgets] = useState([]);
  const [reals, setReals] = useState([]);
  const [performances, setPerformances] = useState([]);
  const [loading, setLoading] = useState(true);
  let { t } = useTranslation();

  const onChangeyear = (date, dateString) => {
    setInvestments();
    console.log(date, dateString);
    setYear(dateString);
  };

  const exportToExcel = (sheets, fileName) => {
    const wb = XLSX.utils.book_new();

    sheets.forEach(({ sheetName, data }) => {
      const ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
    });

    const buf = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });
    saveAs(new Blob([buf], { type: "application/octet-stream" }), fileName);
  };

  const getSummary = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${JSON_API}/Investments/summary?year=${year}&enterpriseId=${Company.id}`
      );
      setBudgets(response.data);
      setReals(response.data);
      setPerformances(response.data);
      setInvestments(response.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${JSON_API}/InvestmentCategorys`);
      setCategories(response.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const calculateMonthlyTotal = (monthIndex, data) => {
    return data.reduce((total, record) => {
      const monthDetail = record.investmentMonthFinanceDetails.find(
        (detail) => detail.month === monthIndex + 1
      );
      const budgetValue = monthDetail ? parseFloat(monthDetail.budget) : 0;

      // const budgetValue = parseFloat(
      //   record.investmentMonthFinanceDetails.find(
      //     (detail) => detail.month === monthIndex
      //   ).budget
      // );

      return total + budgetValue;
    }, 0);
  };

  const calculateTotal = (data) => {
    return data.reduce((total, record) => {
      return total + parseFloat(record.budgetTotal);
    }, 0);
  };

  const calculateMonthlyRealTotal = (monthIndex, data) => {
    return data.reduce((total, record) => {
      const monthDetail = record.investmentMonthFinanceDetails.find(
        (detail) => detail.month === monthIndex + 1
      );
      const realValue = monthDetail ? parseFloat(monthDetail.real) : 0;

      // const realValue = parseFloat(
      //   record.investmentMonthFinanceDetails.find(
      //     (detail) => detail.month === monthIndex
      //   ).real
      // );
      return total + realValue;
    }, 0);
  };

  const calculateRealTotal = (data) => {
    return data.reduce((total, record) => {
      return total + parseFloat(record.realTotal);
    }, 0);
  };

  const calculateMonthlyPerformanceTotal = (monthIndex, data) => {
    return data.reduce((total, record) => {
      const monthDetail = record.investmentMonthFinanceDetails.find(
        (detail) => detail.month === monthIndex + 1
      );
      const performanceValue = monthDetail
        ? parseFloat(monthDetail.performance)
        : 0;

      // const performanceValue = parseFloat(
      //   record.investmentMonthFinanceDetails.find(
      //     (detail) => detail.month === monthIndex
      //   ).performance
      // );
      return total + performanceValue;
    }, 0);
  };

  const calculatePerformanceTotal = (data) => {
    return data.reduce((total, record) => {
      return total + parseFloat(record.performanceTotal);
    }, 0);
  };

  const handlePrint = () => {
    console.log("handlePrint called");
    console.log("Investments:", Investments);
    console.log("Categories:", Categories);
    console.log("Months:", Months);

    if (!Investments || !Categories || !Months) {
      return;
    }

    const formatData = (data, type) => {
      if (!data || !Categories) {
        return [];
      }

      const formattedData = data.map((investment) => {
        const category = investment.investmentCategory
          ? Categories.find(
              (cat) => cat.id === investment.investmentCategory.id
            )?.label || ""
          : "";

        let rowData = {
          projectNumber: investment.projectNumber,
          title: investment.title,
          category,
        };

        Months.forEach((month, index) => {
          const monthDetail = investment.investmentMonthFinanceDetails.find(
            (detail) => detail.month === index + 1
          );

          rowData = {
            ...rowData,
            [`${month}_${type}`]: monthDetail
              ? monthDetail[type].toFixed(0)
              : "0.00",
          };
        });

        return {
          ...rowData,
          total: investment[`${type}Total`].toFixed(0),
        };
      });

      return formattedData;
    };

    const budgetsData = formatData(Investments, "budget");
    const realsData = formatData(Investments, "real");
    const performancesData = Investments.map((investment) => {
      const category =
        Categories.find((cat) => cat.id === investment.investmentCategory.id)
          ?.label || "";

      let rowData = {
        projectNumber: investment.projectNumber,
        title: investment.title,
        category,
      };

      Months.forEach((month, index) => {
        const budgetValue = parseFloat(
          investment.investmentMonthFinanceDetails.find(
            (detail) => detail.month === index + 1
          ).budget
        );
        const realValue = parseFloat(
          investment.investmentMonthFinanceDetails.find(
            (detail) => detail.month === index + 1
          ).real
        );

        rowData = {
          ...rowData,
          [`${month}_difference`]: (budgetValue - realValue).toFixed(0),
        };
      });

      return {
        ...rowData,
        total: (investment.budgetTotal - investment.realTotal).toFixed(0),
      };
    });

    const addTotals = (data, type) => {
      const totals = {
        projectNumber: "",
        title: "Total",
        category: "",
      };

      const grandTotal = data.reduce(
        (acc, row) => {
          let rowTotal = 0;
          Months.forEach((month, index) => {
            const value = parseFloat(row[`${month}_${type}`]);
            rowTotal += value;
            if (acc[`${month}_${type}`]) {
              acc[`${month}_${type}`] += value;
            } else {
              acc[`${month}_${type}`] = value;
            }
          });

          acc.total += rowTotal;
          return acc;
        },
        { total: 0 }
      );

      Months.forEach((month) => {
        totals[`${month}_${type}`] = grandTotal[`${month}_${type}`].toFixed(0);
      });

      totals.total = grandTotal.total.toFixed(0);

      data.push(totals);
    };

    addTotals(budgetsData, "budget");
    addTotals(realsData, "real");
    addTotals(performancesData, "difference");

    console.log("budgetsData:", budgetsData);
    console.log("realsData:", realsData);
    console.log("performancesData:", performancesData);

    const sheets = [
      { sheetName: "Budgets", data: budgetsData },
      { sheetName: "Reals", data: realsData },
      { sheetName: "Performances", data: performancesData },
    ];

    exportToExcel(sheets, `Investment_Summary_${year}.xlsx`);
  };

  useEffect(() => {
    fetchCategories();
    getSummary();
  }, [Company.id, year]);

  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  const startingMonthIndex = Company.startPeriod - 1;

  const columnsBudget = [
    {
      title: t("ProjectNumber"),
      dataIndex: "projectNumber",
      key: "projectNumber",
      align: "center",
      width: 150,
    },
    {
      title: t("Title"),
      dataIndex: "title",
      key: "title",
      align: "center",
      width: 150,
    },
    {
      title: t("Category"),
      dataIndex: "investmentCategory",
      key: "investmentCategory",
      align: "center",
      width: 150,
      render: (text, record) => {
        const category = Categories.find((cat) => cat.id === text.id);
        return <span>{category ? category.label : ""}</span>;
      },
    },
  ];

  months.forEach((month, index) => {
    const monthIndex = (index + startingMonthIndex) % months.length;
    const monthName = months[monthIndex];

    columnsBudget.push({
      title: monthName,
      align: "center",
      dataIndex: `month_${monthIndex}`,
      key: `month_${monthIndex}`,
      render: (text, record) => {
        const monthDetail = record.investmentMonthFinanceDetails.find(
          (detail) => detail.month === monthIndex + 1
        );
        return (
          <div style={{ textAlign: "right" }}>
            {monthDetail ? monthDetail.budget.toFixed(0) : "0.00"}
          </div>
        );
      },
    });
  });

  // ...Months.map((month, index) => ({
  //   title: month,
  //   align: "center",
  //   dataIndex: `month_${index + 1}`,
  //   key: `month_${index + 1}`,
  //   render: (text, record) => {
  //     const monthDetail = record.investmentMonthFinanceDetails.find(
  //       (detail) => detail.month === index + 1
  //     );
  //     return (
  //       <span>{monthDetail ? monthDetail.budget.toFixed(0) : "0.00"}</span>
  //     );
  //   },
  // })),

  columnsBudget.push({
    title: t("BudgetTotal"),
    dataIndex: "budgetTotal",
    align: "center",
    key: "budgetTotal",
    render: (text, record) => (
      <div style={{ textAlign: "right" }}>{text.toFixed(0)}</div>
    ),
  });

  const columnsReal = [
    {
      title: t("ProjectNumber"),
      dataIndex: "projectNumber",
      key: "projectNumber",
      align: "center",
      width: 150,
    },
    {
      title: t("Title"),
      dataIndex: "title",
      key: "title",
      align: "center",
      width: 150,
    },
    {
      title: t("Category"),
      dataIndex: "investmentCategory",
      key: "investmentCategory",
      align: "center",
      width: 100,
      render: (text, record) => {
        const category = Categories.find((cat) => cat.id === text.id);
        return <span>{category ? category.label : ""}</span>;
      },
    },
  ];

  months.forEach((month, index) => {
    const monthIndex = (index + startingMonthIndex) % months.length;
    const monthName = months[monthIndex];

    columnsReal.push({
      title: monthName,
      // width: 120,
      align: "center",
      dataIndex: `month_${monthIndex}`,
      key: `month_${monthIndex}`,
      render: (text, record) => {
        const monthDetail = record.investmentMonthFinanceDetails.find(
          (detail) => detail.month === monthIndex + 1
        );
        return (
          <div style={{ textAlign: "right" }}>
            {monthDetail ? monthDetail.real.toFixed(0) : "0.00"}
          </div>
        );
      },
    });
  });
  // ...Months.map((month, index) => ({
  //   title: month,
  //   align: "center",
  //   dataIndex: `month_${index + 1}`,
  //   key: `month_${index + 1}`,
  //   render: (text, record) => {
  //     const monthDetail = record.investmentMonthFinanceDetails.find(
  //       (detail) => detail.month === index + 1
  //     );
  //     return (
  //       <span>{monthDetail ? monthDetail.real.toFixed(0) : "0.00"}</span>
  //     );
  //   },
  // })),

  columnsReal.push({
    title: t("RealTotal"),
    dataIndex: "realTotal",
    align: "center",
    key: "realTotal",
    render: (text, record) => (
      <div style={{ textAlign: "right" }}>{text.toFixed(0)}</div>
    ),
  });

  const columnsPerformance = [
    {
      title: t("ProjectNumber"),
      dataIndex: "projectNumber",
      key: "projectNumber",
      align: "center",
      width: 150,
    },
    {
      title: t("Title"),
      dataIndex: "title",
      key: "title",
      align: "center",
      width: 150,
    },
    {
      title: t("Category"),
      dataIndex: "investmentCategory",
      key: "investmentCategory",
      align: "center",
      width: 150,
      render: (text, record) => {
        const category = Categories.find((cat) => cat.id === text.id);
        return <span>{category ? category.label : ""}</span>;
      },
    },
  ];

  months.forEach((month, index) => {
    const monthIndex = (month + startingMonthIndex) % months.length;
    const monthName = months[monthIndex];

    columnsPerformance.push({
      title: monthName,
      align: "center",
      dataIndex: `month_${monthIndex}`,
      key: `month_${monthIndex}`,
      children: [
        {
          title: <span className="columnHeader"> {t("Budget")}</span>,
          dataIndex: `budget_month_${monthIndex}`,
          key: `budget_month_${monthIndex}`,
          align: "center",
          width: 100,
          render: (text, record) => {
            const monthDetail = record.investmentMonthFinanceDetails.find(
              (detail) => detail.month === monthIndex + 1
            );
            const budgetValue = monthDetail
              ? parseFloat(monthDetail.budget)
              : 0;

            // const value = parseFloat(
            //   record.investmentMonthFinanceDetails.find(
            //     (detail) => detail.month === monthIndex
            //   ).budget
            // );
            return (
              <div style={{ textAlign: "right" }}>{budgetValue.toFixed(0)}</div>
            );
          },
        },
        {
          title: <span className="columnBlue"> {t("Real")}</span>,
          dataIndex: `real_month_${monthIndex}`,
          key: `real_month_${monthIndex}`,
          align: "center",
          width: 100,
          render: (text, record) => {
            const monthDetail = record.investmentMonthFinanceDetails.find(
              (detail) => detail.month === monthIndex + 1
            );
            const realValue = monthDetail ? parseFloat(monthDetail.real) : 0;

            // const value = parseFloat(
            //   record.investmentMonthFinanceDetails.find(
            //     (detail) => detail.month === monthIndex
            //   ).real
            // );
            return (
              <div style={{ textAlign: "right" }}>{realValue.toFixed(0)}</div>
            );
          },
        },
        {
          title: <span className="columnRed"> {t("Difference")}</span>,
          dataIndex: `diff_month_${monthIndex}`,
          key: `diff_month_${monthIndex}`,
          align: "center",
          width: 100,
          render: (text, record) => {
            const monthDetail = record.investmentMonthFinanceDetails.find(
              (detail) => detail.month === monthIndex + 1
            );

            const budgetValue = monthDetail
              ? parseFloat(monthDetail.budget)
              : 0;

            const realValue = monthDetail ? parseFloat(monthDetail.real) : 0;

            // const budgetValue = parseFloat(
            //   record.investmentMonthFinanceDetails.find(
            //     (detail) => detail.month === monthIndex
            //   ).budget
            // );
            // const realValue = parseFloat(
            //   record.investmentMonthFinanceDetails.find(
            //     (detail) => detail.month === monthIndex
            //   ).real
            // );
            const difference = budgetValue - realValue;
            return (
              <div style={{ textAlign: "right" }}>{difference.toFixed(0)}</div>
            );
          },
        },
      ],
    });
  });
  // ...Months.map((month, index) => {
  //   return {
  //     title: month,
  //     key: `month_${monthIndex}`,
  //     align: "center",
  //     width: 100,
  //     children: [
  //       {
  //         title: <span className="columnHeader"> {t("Budgets")}</span>,
  //         dataIndex: `budget_month_${index + 1}`,
  //         key: `budget_month_${index + 1}`,
  //         align: "center",
  //         width: 100,
  //         render: (text, record) => {
  //           const value = parseFloat(
  //             record.investmentMonthFinanceDetails.find(
  //               (detail) => detail.month === index + 1
  //             ).budget
  //           );
  //           return <span>{value.toFixed(0)}</span>;
  //         },
  //       },
  //       {
  //         title: <span className="columnBlue"> {t("Reals")}</span>,
  //         dataIndex: `real_month_${index + 1}`,
  //         key: `real_month_${index + 1}`,
  //         align: "center",
  //         width: 100,
  //         render: (text, record) => {
  //           const value = parseFloat(
  //             record.investmentMonthFinanceDetails.find(
  //               (detail) => detail.month === index + 1
  //             ).real
  //           );
  //           return <span>{value.toFixed(0)}</span>;
  //         },
  //       },
  //       {
  //         title: <span className="columnRed"> {t("Difference")}</span>,
  //         dataIndex: `diff_month_${index + 1}`,
  //         key: `diff_month_${index + 1}`,
  //         align: "center",
  //         width: 100,
  //         render: (text, record) => {
  //           const budgetValue = parseFloat(
  //             record.investmentMonthFinanceDetails.find(
  //               (detail) => detail.month === index + 1
  //             ).budget
  //           );
  //           const realValue = parseFloat(
  //             record.investmentMonthFinanceDetails.find(
  //               (detail) => detail.month === index + 1
  //             ).real
  //           );
  //           const difference = budgetValue - realValue;
  //           return <span>{difference.toFixed(0)}</span>;
  //         },
  //       },
  //     ],
  //   };
  // }),
  columnsPerformance.push({
    title: t("PerformanceTotal"),
    align: "center",
    width: 300,
    children: [
      {
        title: <span className="columnHeader"> {t("Budgets")}</span>,
        dataIndex: "budgetTotal",
        key: "budgetTotal",
        align: "center",
        render: (_, record) => {
          const value = parseFloat(record.budgetTotal);
          return <div style={{ textAlign: "right" }}>{value.toFixed(0)}</div>;
        },
      },
      {
        title: <span className="columnBlue"> {t("Reals")}</span>,
        dataIndex: "realTotal",
        key: "realTotal",
        align: "center",
        render: (_, record) => {
          const value = parseFloat(record.realTotal);
          return <div style={{ textAlign: "right" }}>{value.toFixed(0)}</div>;
        },
      },
      {
        title: <span className="columnRed"> {t("Difference")}</span>,
        dataIndex: "performanceTotal",
        key: "performanceTotal",
        align: "center",
        render: (_, record) => {
          const budgetValue = parseFloat(record.budgetTotal);
          const realValue = parseFloat(record.realTotal);
          const difference = budgetValue - realValue;
          return (
            <div style={{ textAlign: "right" }}>{difference.toFixed(0)}</div>
          );
        },
      },
    ],
  });

  return (
    <>
      <Card
        title={
          <span style={{ fontSize: "16px" }}>
            <DollarCircleFilled /> {t("InvestmentSummary")}
          </span>
        }
        extra={
          <Link
            to={{
              pathname: `/Investments`,
            }}
          >
            <Button
              // type="primary"
              size="medium"
              className="uppercase"
              style={{
                // borderRadius: "10px",
                textTransform: "uppercase",
                fontSize: "10px",
              }}
            >
              <RollbackOutlined /> {t("BackToInvestments")}
            </Button>
          </Link>
        }
      >
        <Space
          style={{
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Space>
            <DatePicker
              name="year"
              picker="year"
              placeholder={t("SelectedYear")}
              style={{ width: 200, height: 35, marginLeft: 20 }}
              onChange={onChangeyear}
            />
          </Space>
        </Space>

        <Tabs defaultActiveKey="Budget" type="card" centered>
          <TabPane tab={t("Budgets")} key="Budget">
            <Table
              locale={{
                emptyText: <Empty description={t("nodata")} />,
              }}
              columns={columnsBudget}
              dataSource={budgets}
              rowKey="id"
              scroll={{
                x: 3000,
              }}
              bordered
              summary={(pageData) => {
                const items = [];
                for (let i = 0; i < months.length; i++) {
                  const monthIndex = (i + startingMonthIndex) % months.length;
                  items.push(
                    <Table.Summary.Cell className="summary-cell">
                      {calculateMonthlyTotal(monthIndex, pageData).toFixed(0)}
                    </Table.Summary.Cell>
                  );
                }
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={3} className="summary-cell">
                        {t("Total")}
                      </Table.Summary.Cell>
                      {items}
                      <Table.Summary.Cell index={15} className="summary-cell">
                        {calculateTotal(pageData).toFixed(0)}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </TabPane>
          <TabPane tab={t("Reals")} key="Real">
            <Table
              locale={{
                emptyText: <Empty description={t("nodata")} />,
              }}
              columns={columnsReal}
              dataSource={reals}
              rowKey="id"
              scroll={{
                x: 3000,
              }}
              bordered
              summary={(pageData) => {
                const items = [];
                for (let i = 0; i < months.length; i++) {
                  const monthIndex = (i + startingMonthIndex) % months.length;
                  items.push(
                    <Table.Summary.Cell className="summary-cell">
                      {calculateMonthlyRealTotal(monthIndex, pageData).toFixed(
                        0
                      )}
                    </Table.Summary.Cell>
                  );
                }
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={3} className="summary-cell">
                        {t("Total")}
                      </Table.Summary.Cell>
                      {items}
                      <Table.Summary.Cell index={15} className="summary-cell">
                        {calculateRealTotal(pageData).toFixed(0)}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </TabPane>
          <TabPane tab={t("Performances")} key="Performance">
            <Table
              locale={{
                emptyText: <Empty description={t("nodata")} />,
              }}
              columns={columnsPerformance}
              dataSource={performances}
              rowKey="id"
              scroll={{
                x: 3000,
              }}
              bordered
              summary={() => {
                const items = [];
                for (let i = 0; i < months.length; i++) {
                  const monthIndex = (i + startingMonthIndex) % months.length;
                  items.push(
                    <>
                      <Table.Summary.Cell className="summary-cell-green">
                        {calculateMonthlyTotal(
                          monthIndex,
                          performances
                        ).toFixed(0)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className="summary-cell-blue">
                        {calculateMonthlyRealTotal(
                          monthIndex,
                          performances
                        ).toFixed(0)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className="summary-cell-red">
                        {calculateMonthlyPerformanceTotal(
                          monthIndex,
                          performances
                        ).toFixed(0)}
                      </Table.Summary.Cell>
                    </>
                  );
                }
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={3} className="summary-cell">
                        {t("Total")}
                      </Table.Summary.Cell>
                      {items}
                      <Table.Summary.Cell className="summary-cell-green">
                        {calculateTotal(performances).toFixed(0)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className="summary-cell-blue">
                        {calculateRealTotal(performances).toFixed(0)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className="summary-cell-red">
                        {calculatePerformanceTotal(performances).toFixed(0)}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </TabPane>
        </Tabs>
      </Card>
    </>
  );
};

InvestmentSummary.acl = {
  subject: "Investments",
  action: "read",
};

export default InvestmentSummary;
